import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue } from 'bootstrap-vue'
import VueRx from 'vue-rx';
import * as Sentry from "@sentry/vue";
// import VueSocketIOExt from 'vue-socket.io-extended';
// import { io } from 'socket.io-client';

import './styles/app.scss'
import router from './router'
// import { worker } from './mocks/browser';

// if (process.env.VUE_APP_NODE_ENV === 'development') {
//   worker.start()
// }

// Sentry integration
Sentry.init({
  Vue,
  dsn: "https://69c7ec8fc9fa1306be62f4bea2968ff1@o4508484982931456.ingest.de.sentry.io/4508485127766096",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["https://dojo.chimpers.xyz"],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});


Vue.config.productionTip = false

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
// Vue.use(IconsPlugin)

Vue.use(VueRx);

Vue.prototype.$globals = Vue.observable({ chimpers: [] });

// Chatbox Socket plugin for vuejs
// Vue.use(VueSocketIOExt, io('https://chat.chimpers.xyz', {
//   autoConnect: false,
//   transports: ['websocket'],
//   reconnectionDelay: 2000,
//   reconnectionAttempts: 10,
// }));

// Finally, lets render vue application
const app = new Vue({
  router,
  render: h => h(App),
}).$mount('#app');

Vue.prototype.$app = app.$children[0];